<template>
  <div class="dashboard1 p-5" id="dementie-information">
    <div class="row" >
      <div class="col-sm-12 down">
          <h1 class="intro-section-title" v-if="selectedLevel === 'zorgkantoor' || selectedLevel === 'dementienetwerk'">1. Achtergrondkenmerken mensen met dementie {{selectedYear}}</h1>
          <h1 class="intro-section-title" v-if="selectedLevel === 'landelijk'">1. Achtergrondkenmerken mensen met dementie</h1>
          <div class="row">
            <p class="intro-section-text">
            </p>
          </div>
      </div>
    </div>

      <!-- <div class="row">
          <div class="col-sm-6">
            <card :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Migratieachtergrond omvat zowel westers als niet-westerse migratieachtergrond.'">
              <template slot="card-header" >Top 5 Migratieachtergrond </template>
              <div slot="card-body">
                <p class="graphtext-big"> In dit overzicht is de top 5 zorgkantoorregio’s of dementienetwerken zichtbaar met het hoogste percentage mensen met dementie met een migratieachtergrond. Deze categorie omvat zowel mensen met dementie met een westerse als niet-westerse migratieachtergrond.</p>
                <horizontal-bar-chart v-if="load.graphTwo" :xtitle="'% mensen met dementie met migratieachtergrond'" :x="top5migperc" :y="top5mignaam" :marginleft="200"/>
              </div>
            </card>
          </div>
          <div class="col-sm-6">
            <card :hovertext="'Leeftijd op 1 januari. Alleen observaties voor mensen van 39 jaar (die worden 40 in kalenderjaar) of ouder behouden.'">
              <template slot="card-header" >Top 5 regio's mensen met dementie op jonge leeftijd </template>
              <div slot="card-body">
                <p class="graphtext-big">   In dit overzicht is de top 5 zorgkantoorregio’s of dementienetwerken zichtbaar met het hoogste percentage mensen met dementie op jonge leeftijd. Mensen met dementie met een leeftijd tussen 40 en 65 vallen in deze categorie. </p>
                <horizontal-bar-chart v-if="load.graphOne" :xtitle="'% mensen met dementie op jonge leeftijd'" :x="top5jongperc" :y="top5jongnaam" :marginleft="200"/>
              </div>
            </card>
          </div>
        </div> -->

        <div class="row" v-if="selectedLevel !== 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="`Migratieachtergrond mensen met dementie in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="zorgkantoorregionaam" :y="migratieachtergrond_table.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Percentage'" 
                :tableHeight="selectedLevel === 'dementienetwerk' ? 1600 : 900">
                <template slot="card-header">Migratieachtergrond mensen met dementie in {{selectedYear}}</template>
                <div slot="card-body">
                  In onderstaand diagram is te zien hoe het geselecteerde zorgkantoor of dementienetwerk zich verhoudt tot het landelijk gemiddelde op het gebied van migratieachtergrond. 
                  <stacked-bar-chart-colors v-if="load.graphTwo"
                    :ytitle="'% met migratieachtergrond'"
                    :x="[zorgkantoorregionaam]"
                    :y="[migratieachtergrond]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>
        <div class="row" v-if="selectedLevel !== 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="`Verdeling leeftijd mensen met dementie in ${selectedYear}`" :hovertext="'Leeftijd op 1 januari. Alleen observaties voor mensen van 39 jaar (die worden 40 in kalenderjaar) of ouder behouden.'" 
                :x="zorgkantoorregionaam_leeftijd" :y="categorie_leeftijd" 
                :z="percentage_leeftijd.map(value => `${value}%`)"
                :xtitle="'Regio naam'" :ytitle="'Categorie'" :ztitle="'Percentage'" 
                :tableHeight="selectedLevel === 'dementienetwerk' ? 3000 : 3500">
                <template slot="card-header">Verdeling leeftijd mensen met dementie in {{selectedYear}}</template>
                <div slot="card-body">
                  In onderstaand diagram is te zien hoe het geselecteerde zorgkantoor of dementienetwerk zich verhoudt tot het landelijk gemiddelde op het gebied van leeftijdsverdeling van mensen met dementie.
                    <stacked-bar-chart-colors v-if="load.graphOne" 
                    :ytitle="'% in leeftijdsgroep'"
                    :names="['< 65 jaar', '66 - 79 jaar', '80 - 89 jaar', '90 jaar en ouder']"
                    :x="[zorgkantoorregionaam_65, zorgkantoorregionaam_66, zorgkantoorregionaam_80, zorgkantoorregionaam_90]"
                    :y="[leeftijd_65, leeftijd_66, leeftijd_80, leeftijd_90]"
                    :ticksuffix="'%'"
                    />
                </div>
              </card>
            </div>
        </div>
        <div class="row" v-if="selectedLevel !== 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="`Woonsituatie mensen met dementie in ${selectedYear}`" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Het aantal mensen dat in een zorginstelling woont komt niet overeen met het aantal mensen dat WLZ-zorg met verblijf ontvangt dat elders gerapporteerd wordt. Dat komt omdat voor deze grafiek gebruik wordt gemaakt van een andere bron: het Basisregister Personen (BRP). Pas als iemand een verhuizing naar een zorginstelling doorgeeft wordt hij/zij als bewoner van een zorginstelling aangemerkt. Daarnaast dient de locatie van de zorginstelling aangemerkt als zodanig aangemerkt te zijn in het BRP. Tot slot nemen we hier de huishoudenssamenstelling op 1 januari als uitgangspunt en wordt dus iedereen die gedurende het jaar naar een zorginstelling verhuist niet meegenomen in deze cijfers.'" 
              :x="zorgkantoorregionaam_woonsituatie" :y="categorie_woonsituatie" :z="percentage_woonsituatie.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Categorie'" :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 4000 : 2300">
              <template slot="card-header">Woonsituatie mensen met dementie in {{selectedYear}}</template>
              <div slot="card-body">
                <div class="text">
                In onderstaand diagram is te zien hoe het geselecteerde netwerk/zorgkantoor zich verhoudt tot het landelijk gemiddelde op het gebied van woonsituatie van mensen met dementie.
                </div>
                <div class="row fluid">
                  <div class="col-sm-7">
                    <stacked-bar-chart-colors v-if="load.graphThree"
                    :ytitle="'% woonsituatie'"
                    :xtitle="''"
                    :names="['Zorginstelling', 'Meerpersoons Huishouden', 'Eenpersoons Huishouden']"
                    :x="[zorgkantoorregionaam_zi, zorgkantoorregionaam_mp, zorgkantoorregionaam_ep]"
                    :y="[zorginstelling, meerpersoons, eenpersoons]"
                    :title="'Regio Benchmark'"
                    :ticksuffix="'%'"
                    />
                  </div>
                  <div class="col-sm-5">
                      <stacked-bar-chart-colors-multi v-if="load.graphFour" :tickangle="0"
                      :name3="'Eenpersoons Huishouden'"
                      :x3="ddt_zorgkantoorregionaam_ep"
                      :xx3="ddt_jaar_ep"
                      :height= "360"
                      :y3="ddt_eenpersoons"
                      :name2="'Meerpersoons Huishouden'"
                      :x2="ddt_zorgkantoorregionaam_mp"
                      :xx2="ddt_jaar_mp"
                      :y2="ddt_meerpersoons"
                      :name1="'Zorginstelling'"
                      :x1="ddt_zorgkantoorregionaam_zi"
                      :xx1="ddt_jaar_zi"
                      :y1="ddt_zorginstelling" />
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>

        <div class="row" v-if="selectedLevel !== 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="`Verdeling inkomen mensen met dementie in ${selectedYear}`" :hovertext="'Deze grafiek toont het gestandaardiseerd huishoudensinkomen van mensen met dementie. Het inkomen is niet gecorrigeerd voor inflatie. De 4 categorieën zijn gebaseerd op het gestandaardiseerd huishoudensinkomen. Huishoudens met een ontbrekend of negatief inkomen zijn ingedeeld in de laagste categorie inkomen.'"
              :x="zorgkantoorregionaam_inkomen" :y="categorie_inkomen" :z="percentage_inkomen.map(value => `${value}%`)"
              :xtitle="'Regio naam'" :ytitle="'Categorie'" :ztitle="'Percentage'" 
              :tableHeight="selectedLevel === 'dementienetwerk' ? 6000 : 3000">
              <template slot="card-header">Verdeling inkomen mensen met dementie in {{selectedYear}}</template>
              <div slot="card-body">
                Onderstaande grafiek toont de verdeling van inkomen van mensen met dementie. Het percentage mensen met een inkomen onder de 15.000 euro neemt af over de jaren voor vrijwel elke regio.
                  <stacked-bar-chart-colors v-if="load.graphFive" 
                  :ticksuffix="'%'"
                  :ytitle="'% in inkomensgroep'" 
                  :names="['< €15.000', '€15.000-€20.000', '€20.000-€30.000', '€30.000 +']"
                  :x="[zorgkantoorregionaam_10k, zorgkantoorregionaam_20k, zorgkantoorregionaam_30k, zorgkantoorregionaam_40k]"
                  :y="[inkomen_10k, inkomen_20k, inkomen_30k, inkomen_40k]"/>
              </div>
            </card>
          </div>
        </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
            <div class="col-sm-12 down">
              <card :cardId="'Migratieachtergrond mensen met dementie'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="migratieachtergrond_jaar" :y="migratieachtergrond_table.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Percentage'" 
                :tableHeight="300">
                <template slot="card-header">Migratieachtergrond mensen met dementie</template>
                <div slot="card-body">
                  Deze grafiek gaat over het nationale gemiddelde wat betreft migratieachtergrond. 
                  <stacked-bar-chart-colors v-if="load.graphSix" :isLandelijk="false"
                  :tickangle="0"
                    :ytitle="'% met migratieachtergrond'"
                    :xtitle="'Jaar'"
                    :x="[migratieachtergrond_jaar]"
                    :y="[migratieachtergrond_2]"
                    :showlegend="false"
                    :ticksuffix="'%'"/>
                </div>
              </card>
            </div>
        </div>


        <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Verdeling leeftijd mensen met dementie'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="jaar_leeftijd" :y="categorie_leeftijd" 
                :z="percentage_leeftijd.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Categorie'" :ztitle="'Percentage'" 
                :tableHeight="600">
                <template slot="card-header">Verdeling leeftijd mensen met dementie</template>
                <div slot="card-body">
                  Deze grafiek toont het landelijk gemiddelde van de leeftijdsverdeling van mensen met dementie.
                    <stacked-bar-chart-colors-multi v-if="load.graphSeven" :tickangle="0"
                    :isLandelijk='false'
                    :height= "360"
                    :name4="'<65'"
                    :x4="landelijk_leeftijd_65"
                    :xx4="jaar_landel_65"
                    :y4="prc_landel_65"
                    :name3="'65-79'"
                    :x3="landelijk_leeftijd_79"
                    :xx3="jaar_landel_79"
                    :y3="prc_landel_79"
                    :name2="'80-89'"
                    :x2="landelijk_leeftijd_80"
                    :xx2="jaar_landel_80"
                    :y2="prc_landel_80"
                    :name1="'90+'"
                    :x1="landelijk_leeftijd_90"
                    :xx1="jaar_landel_90"
                    :y1="prc_landel_90" />
                  </div>
                </card>
              </div>
            </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Woonsituatie mensen met dementie'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'" 
                :x="jaar_woonsituatie" :y="categorie_woonsituatie" 
                :z="percentage_woonsituatie.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Categorie'" :ztitle="'Percentage'" 
                :tableHeight="550">
                <template slot="card-header">Woonsituatie mensen met dementie</template>
                <div slot="card-body" style="margin-bottom: 2rem">
                  Deze grafiek geeft het landelijk gemiddelde weer van de woonsituatie van mensen met dementie.
                      <stacked-bar-chart-colors-multi v-if="load.graphEight" :tickangle="0"
                      :isLandelijk='false'
                      :height= "360"
                      :name3="'Eenpersoons Huishouden'"
                      :x3="landel_zorgkantoorregionaam_ep"
                      :xx3="landel_jaar_ep"
                      :y3="landel_eenpersoons"
                      :name2="'Meerpersoons Huishouden'"
                      :x2="landel_zorgkantoorregionaam_mp"
                      :xx2="landel_jaar_mp"
                      :y2="landel_meerpersoons"
                      :name1="'Zorginstelling'"
                      :x1="landel_zorgkantoorregionaam_zi"
                      :xx1="landel_jaar_zi"
                      :y1="landel_zorginstelling" />
                  </div>
                </card>
              </div>
            </div>

        <div class="row" v-if="selectedLevel === 'landelijk'">
          <div class="col-sm-12 down">
            <card :cardId="'Verdeling inkomen mensen met dementie'" :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database. Mensen met een migratieachtergrond zijn in een ander land geboren of hebben een ouder die in een ander land geboren is.'"
              :x="jaar_inkomen" :y="categorie_inkomen" 
                :z="percentage_inkomen.map(value => `${value}%`)"
                :xtitle="'Jaar'" :ytitle="'Categorie'" :ztitle="'Percentage'" 
                :tableHeight="550">
                <template slot="card-header">Verdeling inkomen mensen met dementie</template>
                <div slot="card-body">
                  Onderstaande grafiek toont het landelijk gemiddelde van de inkomensverdeling van mensen met dementie.
                      <stacked-bar-chart-colors-multi v-if="load.graphNine" :tickangle="0"
                      :isLandelijk='false'
                      :height= "360"
                      :name4="'0-14999'"
                      :x4="landelijk_10k"
                      :xx4="jaar_inkomen_10k"
                      :y4="inkomen_10k_perc"
                      :name3="'15000-19999'"
                      :x3="landelijk_20k"
                      :xx3="jaar_inkomen_20k"
                      :y3="inkomen_20k_perc"
                      :name2="'20000-29999'"
                      :x2="landelijk_30k"
                      :xx2="jaar_inkomen_30k"
                      :y2="inkomen_30k_perc"
                      :name1="'30000+'"
                      :x1="landelijk_40k"
                      :xx1="jaar_inkomen_40k"
                      :y1="inkomen_40k_perc" />
                  </div>
                </card>
              </div>
            </div>

<!--         <div>
          <p></p>
          <p>Ontwikkeld door ADC en ESHPM in opdracht van het ministerie van Volksgezondheid, Welzijn en Sport. Voor vragen of opmerkingen kunt u contact opnemen met <a class="custom-link" href="mailto:dementiedata@amsterdamdatacollective.com">dementiedata@amsterdamdatacollective.com</a>.</p>
        </div>

        <div class="row custom-margin">
          <div class="col-sm-6 center">
            <div class="item">
              <a href="https://www.eur.nl/eshpm/onderzoek/onderzoeksgroepen/health-economics/themas/ouderenzorg" target="_blank" rel="noopener">
                <img src="../assets/erasmuslogo.png" class="header-logo" height="50vh"/>
              </a>
            </div>
          </div>
          <div class="col-sm-6 center">
            <div class="item">
              <a href="https://amsterdamdatacollective.com/" target="_blank" rel="noopener">
                <img src="../assets/ADC full logo.png" class="header-logo" height="40vh"/>
              </a>
            </div>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-sm-12 down">
            <card :hovertext="'Data op basis van gestandaardiseerd huishoudensinkomen. Huishoudens met een ontbrekend of negatief inkomen zijn ingedeeld in deze laagste categorie inkomen'">
              <template slot="card-header" >Percentage mensen met dementie met inkomen minder dan 15000 euro in {{selectedYear}}</template>
              <div slot="card-body">
                Deze grafiek toont het percentage mensen met dementie per zorgkantoor/dementienetwerk met een inkomen lager dan 15k per jaar. In 2019 wonen, net als in 2018 en 2017, in de zorgkantoorregio’s en dementienetwerken in Amsterdam en Rotterdam relatief veel mensen met dementie met een inkomen van 15.000 euro per jaar of minder.
                <horizontal-bar-chart v-if="load.graphFive" :xtitle="'% onder de armoedegrens'" :x="inkomen_10k_top6" :y="zorgkantoorregionaam_10k_top6" :marginleft="200" />
              </div>
            </card>
          </div>
        </div> -->
<!--       </div>
  </div> -->


    <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
  </template>
<script>

// import SideBarRight from '../components/SideBarRight'
// import HorizontalBarChart from '../components/HorizontalBarChart'
import StackedBarChartColors from '../components/StackedBarChartColors'
import StackedBarChartColorsMulti from '../components/StackedBarChartColorsMulti'
import Card from '../components/Card'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Thema Achtergrondkenmerken',
  },
  components: { Card, StackedBarChartColors, StackedBarChartColorsMulti, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionaam_65: [],
      leeftijd_65: [],
      zorgkantoorregionaam_66: [],
      leeftijd_66: [],
      zorgkantoorregionaam_80: [],
      leeftijd_80: [],
      zorgkantoorregionaam_90: [],
      leeftijd_90: [],
      percentage_leeftijd: [],
      zorgkantoorregionaam_leeftijd :[],
      categorie_leeftijd :[],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false,
        graphFour: false,
        graphFive: false,
        graphSix: false,
        graphSeven: false,
        graphEight: false,
        graphNine: false,
      },
      geenmigratieachtergrond: [],
      migratieachtergrond_table:[],
      zorgkantoorregionaam: [],
      migratieachtergrond: [],

      landelijk_leeftijd_65:[],
      jaar_landel_65:[],
      prc_landel_65:[],
      landelijk_leeftijd_79:[],
      jaar_landel_79:[],
      prc_landel_79:[],
      landelijk_leeftijd_80:[],
      jaar_landel_80:[],
      prc_landel_80:[],
      landelijk_leeftijd_90:[],
      jaar_landel_90:[],
      prc_landel_90:[],
      jaar_leeftijd :[],

      landel_zorgkantoorregionaam_ep:[],
      landel_jaar_ep : [],
      landel_eenpersoons : [],
      landel_zorgkantoorregionaam_mp : [],
      landel_meerpersoons : [],
      landel_jaar_mp : [],
      landel_zorgkantoorregionaam_zi : [],
      landel_zorginstelling : [],
      landel_jaar_zi : [],

      zorgkantoorregionaam_woonsituatie :[],
      categorie_woonsituatie :[],
      percentage_woonsituatie :[],

      zorgkantoorregionaam_inkomen :[],
      categorie_inkomen :[],
      percentage_inkomen :[],

      landelijk_10k: [],
      inkomen_10k_perc : [],
      jaar_inkomen_10k : [],
      landelijk_20k : [],
      inkomen_20k_perc : [],
      jaar_inkomen_20k : [],
      landelijk_30k : [],
      inkomen_30k_perc : [],
      jaar_inkomen_30k : [],
      landelijk_40k : [],
      inkomen_40k_perc : [],
      jaar_inkomen_40k : [],

      top5mignaam: [],
      top5migperc: [],
      top5jongnaam: [],
      top5jongperc: [],
      zorgkantoorregionaam_ep: [],
      zorgkantoorregionaam_mp: [],
      zorgkantoorregionaam_zi: [],
      eenpersoons: [],
      meerpersoons: [],
      zorginstelling: [],
      ddt_zorgkantoorregionaam_ep: [],
      ddt_zorgkantoorregionaam_mp: [],
      ddt_zorgkantoorregionaam_zi: [],
      ddt_eenpersoons: [],
      ddt_meerpersoons: [],
      ddt_zorginstelling: [],
      ddt_jaar_mp: [],
      ddt_jaar_ep: [],
      ddt_jaar_i: [],

      migratieachtergrond_jaar: [],
      jaar_woonsituatie: [],
      jaar_inkomen: [],
      migratieachtergrond_2:[]

    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor') {
        this.$http.get(`/api/zorgkantoor_leeftijd/get_data/?jaar=${this.$store.state.selectedYear}&dementie=True`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.zorgkantoorregionaam)
            this.leeftijd_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => (d.percentage))
            this.zorgkantoorregionaam_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.zorgkantoorregionaam)
            this.leeftijd_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => (d.percentage))
            this.zorgkantoorregionaam_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.zorgkantoorregionaam)
            this.leeftijd_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => (d.percentage))
            this.zorgkantoorregionaam_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.zorgkantoorregionaam)
            this.leeftijd_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => (d.percentage))
            this.top5jongnaam = response.data.filter(x => x.leeftijdscategorie =='<65').sort((a,b) => a.percentage-b.percentage).map(d => d.zorgkantoorregionaam).slice(-5)
            this.top5jongperc = response.data.filter(x => x.leeftijdscategorie =='<65').sort((a,b) => a.percentage-b.percentage).map(d => (d.percentage)).slice(-5)

            this.zorgkantoorregionaam_leeftijd = response.data.map(d => d.zorgkantoorregionaam)
            this.percentage_leeftijd = response.data.map(d => (d.percentage).toFixed(1))
            this.categorie_leeftijd = response.data.map(x => x.leeftijdscategorie)
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_migratieachtergrond/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam = response.data.map(d => d.zorgkantoorregionaam)
            this.migratieachtergrond = response.data.map(d => (d.percentage))
            this.migratieachtergrond_table = response.data.map(d => (d.percentage).toFixed(1))
            response.data.sort((a,b) => a.percentage-b.percentage)
            this.top5mignaam = response.data.map(d => d.zorgkantoorregionaam).slice(-5)
            this.top5migperc = response.data.map(d => (d.percentage)).slice(-5)
            this.load.graphTwo=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))

            this.zorgkantoorregionaam_woonsituatie = response.data.map(d => d.zorgkantoorregionaam)
            this.percentage_woonsituatie = response.data.map(d => (d.percentage).toFixed(1))
            this.categorie_woonsituatie = response.data.map(d => d.typehuishouden)
            this.load.graphThree=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?regio=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.ddt_zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.ddt_jaar_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.ddt_eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.ddt_zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.ddt_meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.ddt_jaar_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.ddt_zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.ddt_zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.ddt_jaar_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)
            this.load.graphFour=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_inkomen/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.zorgkantoorregionaam_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam)
            this.inkomen_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.zorgkantoorregionaam_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.zorgkantoorregionaam)
            this.inkomen_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.zorgkantoorregionaam_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.zorgkantoorregionaam)
            this.inkomen_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.zorgkantoorregionaam_40k = response.data.filter(x => x.inkomen =='30000+').map(d => d.zorgkantoorregionaam)
            this.inkomen_40k = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))

            response.data.sort((a,b) => a.percentage-b.percentage)
            this.zorgkantoorregionaam_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam).slice(-6)
            this.inkomen_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage)).slice(-6)

            this.zorgkantoorregionaam_inkomen =response.data.map(d => d.zorgkantoorregionaam)
            this.categorie_inkomen = response.data.map(x => x.inkomen)
            this.percentage_inkomen =response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphFive=true
          }
        )
      }
      else if (level ==='landelijk'){
        this.$http.get(`/api/zorgkantoor_migratieachtergrond/get_data/?jaar=${this.$store.state.selectedYear}&landelijk=True`).then(
          (response) => {
            response.data.sort((a, b) => a.jaar - b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.migratieachtergrond_jaar = response.data.map(d => d.jaar)
            this.migratieachtergrond_2 = response.data.map(d => (d.percentage_migratieachtergrond))
            this.migratieachtergrond_table = response.data.map(d => (d.percentage_migratieachtergrond).toFixed(1))
            response.data.sort((a,b) => a.percentage_migratieachtergrond-b.percentage_migratieachtergrond)
            this.load.graphSix=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_leeftijd/get_data/?dementie=True&landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            this.landelijk_leeftijd_65 = response.data.filter(x => x.leeftijdscategorie == "<65").map(d => d.zorgkantoorregionaam)
            this.jaar_landel_65 = response.data.filter(x => x.leeftijdscategorie == "<65").map(d => d.jaar)
            this.prc_landel_65 = response.data.filter(x => x.leeftijdscategorie =="<65").map(d => (d.percentage))
            
            this.landelijk_leeftijd_79 = response.data.filter(x => x.leeftijdscategorie == "65-79").map(d => d.zorgkantoorregionaam)
            this.prc_landel_79 = response.data.filter(x => x.leeftijdscategorie =="65-79").map(d => (d.percentage))
            this.jaar_landel_79= response.data.filter(x => x.leeftijdscategorie == "65-79").map(d => d.jaar)

            this.landelijk_leeftijd_80 = response.data.filter(x => x.leeftijdscategorie == "80-89").map(d => d.zorgkantoorregionaam)
            this.prc_landel_80 = response.data.filter(x => x.leeftijdscategorie =="80-89").map(d => (d.percentage))
            this.jaar_landel_80 = response.data.filter(x => x.leeftijdscategorie == "80-89").map(d => d.jaar)

            this.landelijk_leeftijd_90 = response.data.filter(x => x.leeftijdscategorie == "90+").map(d => d.zorgkantoorregionaam)
            this.prc_landel_90 = response.data.filter(x => x.leeftijdscategorie =="90+").map(d => (d.percentage))
            this.jaar_landel_90 = response.data.filter(x => x.leeftijdscategorie == "90+").map(d => d.jaar)

            this.percentage_leeftijd = response.data.map(d => (d.percentage).toFixed(1))
            this.categorie_leeftijd = response.data.map(x => x.leeftijdscategorie)
            this.jaar_leeftijd = response.data.map(d => d.jaar)
            this.load.graphSeven=true
          }
        ),

        this.$http.get(`/api/zorgkantoor_huishouden/get_data/?regio=${this.$store.state.selectedRegion}&landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.landel_zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.zorgkantoorregionaam)
            this.landel_jaar_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.landel_eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.landel_zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.zorgkantoorregionaam)
            this.landel_meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.landel_jaar_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.landel_zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.zorgkantoorregionaam)
            this.landel_zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.landel_jaar_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)

            this.jaar_woonsituatie = response.data.map(d => d.jaar)
            this.categorie_woonsituatie = response.data.map(x => x.typehuishouden)
            this.percentage_woonsituatie = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphEight=true
          }
        ),
        this.$http.get(`/api/zorgkantoor_inkomen/get_data/?landelijk=True`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            response.data = response.data.filter(x => x.zorgkantoorregionaam !=='Missing')
            this.landelijk_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => d.zorgkantoorregionaam)
            this.inkomen_10k_perc = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.jaar_inkomen_10k= response.data.filter(x => x.inkomen == "0-14999").map(d => d.jaar)

            this.landelijk_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.zorgkantoorregionaam)
            this.inkomen_20k_perc = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.jaar_inkomen_20k= response.data.filter(x => x.inkomen == "15000-19999").map(d => d.jaar)

            this.landelijk_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.zorgkantoorregionaam)
            this.inkomen_30k_perc = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.jaar_inkomen_30k= response.data.filter(x => x.inkomen == "20000-29999").map(d => d.jaar)

            this.landelijk_40k = response.data.filter(x => x.inkomen =='30000+').map(d => d.zorgkantoorregionaam)
            this.inkomen_40k_perc = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))
            this.jaar_inkomen_40k= response.data.filter(x => x.inkomen == "30000+").map(d => d.jaar)

            this.jaar_inkomen = response.data.map(d => d.jaar)
            this.categorie_inkomen = response.data.map(x => x.inkomen)
            this.percentage_inkomen = response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphNine=true
          }
        )
      }
      else {
        this.$http.get(`/api/dementienetwerk_leeftijd/get_data/?jaar=${this.$store.state.selectedYear}&dementie=True`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => d.dementienetwerknaam)
            this.leeftijd_65 = response.data.filter(x => x.leeftijdscategorie =='<65').map(d => (d.percentage))
            this.zorgkantoorregionaam_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => d.dementienetwerknaam)
            this.leeftijd_66 = response.data.filter(x => x.leeftijdscategorie =='65-79').map(d => (d.percentage))
            this.zorgkantoorregionaam_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => d.dementienetwerknaam)
            this.leeftijd_80 = response.data.filter(x => x.leeftijdscategorie =='80-89').map(d => (d.percentage))
            this.zorgkantoorregionaam_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => d.dementienetwerknaam)
            this.leeftijd_90 = response.data.filter(x => x.leeftijdscategorie =='90+').map(d => (d.percentage))
            this.top5jongnaam = response.data.filter(x => x.leeftijdscategorie =='<65').sort((a,b) => a.percentage-b.percentage).map(d => d.dementienetwerknaam).slice(-5)
            this.top5jongperc = response.data.filter(x => x.leeftijdscategorie =='<65').sort((a,b) => a.percentage-b.percentage).map(d => (d.percentage)).slice(-5)
            this.load.graphOne=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_migratieachtergrond/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam = response.data.map(d => d.dementienetwerknaam)
            this.migratieachtergrond = response.data.map(d => (d.percentage))
            this.migratieachtergrond_table = response.data.map(d => (d.percentage).toFixed(1))
            response.data.sort((a,b) => a.percentage-b.percentage)
            this.top5mignaam = response.data.map(d => d.dementienetwerknaam).slice(-5)
            this.top5migperc = response.data.map(d => (d.percentage)).slice(-5)
            this.load.graphTwo=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_huishouden/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            this.zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.dementienetwerknaam)
            this.eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.dementienetwerknaam)
            this.meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.dementienetwerknaam)
            this.zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))

            this.zorgkantoorregionaam_woonsituatie = response.data.map(d => d.dementienetwerknaam)
            this.percentage_woonsituatie = response.data.map(d => (d.percentage).toFixed(1))
            this.categorie_woonsituatie = response.data.map(x => x.typehuishouden)
            this.load.graphThree=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_huishouden/get_data/?netwerk=${this.$store.state.selectedRegion}`).then(
          (response) => {
            response.data.sort((a,b) => a.jaar-b.jaar)
            this.ddt_zorgkantoorregionaam_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.dementienetwerknaam)
            this.ddt_jaar_ep = response.data.filter(x => x.typehuishouden == "Eenpersoons").map(d => d.jaar)
            this.ddt_eenpersoons = response.data.filter(x => x.typehuishouden =="Eenpersoons").map(d => (d.percentage))

            this.ddt_zorgkantoorregionaam_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.dementienetwerknaam)
            this.ddt_meerpersoons = response.data.filter(x => x.typehuishouden =="Meerpersoons").map(d => (d.percentage))
            this.ddt_jaar_mp = response.data.filter(x => x.typehuishouden == "Meerpersoons").map(d => d.jaar)
            this.ddt_zorgkantoorregionaam_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.dementienetwerknaam)
            this.ddt_zorginstelling = response.data.filter(x => x.typehuishouden =="Institutioneel").map(d => (d.percentage))
            this.ddt_jaar_zi = response.data.filter(x => x.typehuishouden == "Institutioneel").map(d => d.jaar)
            this.load.graphFour=true
          }
        ),
        this.$http.get(`/api/dementienetwerk_inkomen/get_data/?jaar=${this.$store.state.selectedYear}`).then(
          (response) => {
            response.data.sort((a,b) => b.percentage-a.percentage)
            response.data = response.data.filter(x => x.dementienetwerknaam !=='Missing')
            this.zorgkantoorregionaam_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => d.dementienetwerknaam)
            this.inkomen_10k = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage))
            this.zorgkantoorregionaam_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => d.dementienetwerknaam)
            this.inkomen_20k = response.data.filter(x => x.inkomen =='15000-19999').map(d => (d.percentage))
            this.zorgkantoorregionaam_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => d.dementienetwerknaam)
            this.inkomen_30k = response.data.filter(x => x.inkomen =='20000-29999').map(d => (d.percentage))
            this.zorgkantoorregionaam_40k = response.data.filter(x => x.inkomen =='30000+').map(d => d.dementienetwerknaam)
            this.inkomen_40k = response.data.filter(x => x.inkomen =='30000+').map(d => (d.percentage))

            response.data.sort((a,b) => a.percentage-b.percentage)
            this.zorgkantoorregionaam_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => d.dementienetwerknaam).slice(-6)
            this.inkomen_10k_top6 = response.data.filter(x => x.inkomen =='0-14999').map(d => (d.percentage)).slice(-6)

            this.zorgkantoorregionaam_inkomen =response.data.map(d => d.dementienetwerknaam)
            this.categorie_inkomen = response.data.map(x => x.inkomen)
            this.percentage_inkomen =response.data.map(d => (d.percentage).toFixed(1))
            this.load.graphFive=true
          }
        )
      }
    }
  },
  mounted () {
    this.loadAllData(this.selectedLevel)
  }
}

</script>


<style scoped>

.graphtext-big {
  height: 60px;
}


.intro-section-title{
  font-weight: bold;
}

/*.intro-section-text{
  color: #1f2a44;
}*/

</style>
